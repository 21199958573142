import '@babel/polyfill';
import Splitting from 'splitting';
import { gsap } from "gsap";
// gsap.install(window);

// import * as basicScroll from 'basicscroll'
import utils from './utils/utils';
import contactVideoCanvas from './video-canvas';
import mainCanvas from './main-canvas-event-handler';

import portfolioShiftEffect from './vendor/RGBShiftEffect';
// import fastReader from './fastreader';

export default class App {
	constructor() {
		this.initCore();
		this.initBody();
	}

	switchText() {
		var tl = new TimelineMax({ paused: false });
		let time = 1;
		let boxes = document.querySelectorAll('.text-switch__item');
		tl.to('.text-switch__item-', time, { opacity: 0 }, time);

		tl.staggerTo(boxes, time / 2, {
			opacity: 1,
			onStart: function () {
				TweenMax.staggerTo(boxes, time / 2, {
					opacity: 0,
					delay: time,

				}, 1, function () {
					TweenMax.to('.text-switch__item--', time, { opacity: 1 });

				});
			}
		}, 1);

	}

	detectSections() {
		portfolioShiftEffect();

			if (!utils.isMobile()) {
				// contactVideoCanvas();
			}



	}


	handleProjectHover() {
		let textelement = document.querySelector('.work__float__desc .next'),
			workitems = document.querySelector('.work__items'),
			text, work, company;

		utils.getElem('.work__item', (el) => {
			el.addEventListener('mouseenter', function () {
				text = el.querySelector('[data-text]').innerHTML;
				work = el.querySelector('[data-work]').innerHTML;
				company = el.getAttribute('data-agency');
				appendText(text, work, company);
			})
		})


		if (workitems) {
			workitems.addEventListener('mouseleave', function () {
				appendText('How am i supposed to pick from such many?');
			})
		}

		const appendText = (text, work, company) => {

			document.querySelector('.work__float__desc').innerHTML += `
			<div style="transform:translateY(-35px);" class="next">
				<span>${text}
				
				<span>${work ? `<i>${work}</i> <i>${company}</i>` : ''}</span>
				</span>
			</div>`;
			TweenMax.to('.work__float__desc .next', .2, {
				x: '0',
				opacity: 1,
				onStart: () => {
					document.querySelector('.work__float__desc .next').remove();
				},
				ease: Power3.easeIn,
			});
		}
	}

	appLoaderQue() {
		mainCanvas();


		// document.addEventListener('click', () => {
		// 	TweenMax.to('section', 10, {
		// 		y: '-333%',
		// 		ease: Power0.easeNone,
		// 	});
		// })

		// TODO
		// if (window.location.search != '?goo') {
		// 	document.querySelector('main').innerHTML = '';
		// 	document.querySelector('header').innerHTML = '';
		// }


		this.detectSections();
		this.handleProjectHover();
	}


	preventClickMobile() {
		utils.getElem('.work__item', function (el) {

			el.addEventListener('touchstart', function (e) {
				utils.getElem('.work__item', function (el) {
					el.classList.remove('active');
				})
				el.classList.add('active');
			})

			el.addEventListener('click', function (e) {
				if (!el.classList.contains('go')) {
					e.preventDefault();
				}
				setTimeout(() => {


					utils.getElem('.work__item', function (el) {
						el.classList.remove('go');
					})
					el.classList.add('go');

				}, 10);
			})



		})
	}

	initBody() {


		Splitting();
		if (utils.isMobile()) {
			this.preventClickMobile();
		}
	}

	initCore() {
		this.appLoaderQue();
	}
}

function LoadApp() {
	const app = new App();
}

if (document.readyState === 'loading') {  // Loading hasn't finished yet
	document.addEventListener('DOMContentLoaded', LoadApp);
} else {  // `DOMContentLoaded` has already fired
	LoadApp();
}