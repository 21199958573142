// blob
import '@babel/polyfill';

import * as THREE from '../../../node_modules/three/build/three.module';
import Scrollbar from 'smooth-scrollbar';
import { gsap, Elastic } from "gsap"; gsap.install(window);
// gsap.install(window);

import { EffectComposer } from './vendor/three/three.js-master/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from './vendor/three/three.js-master/examples/jsm/postprocessing/RenderPass';
// import { GlitchPass } from './vendor/three/three.js-master/examples/jsm/postprocessing/GlitchPass';
import { UnrealBloomPass } from './vendor/three/three.js-master/examples/jsm/postprocessing/UnrealBloomPass';
import utils from './utils/utils';
// import fastReader from './fastreader';
export default function () {

	console.warn = function () { };
	let ismobile = utils.isMobile();

	var renderer = new THREE.WebGLRenderer({ precision: 'lowp', powerPreference: "high-performance", antialias: false, alphaTest: true }),
		body = document.querySelector('body'),
		composer;

	renderer.setPixelRatio(ismobile ? 2 : 1);

	renderer.setClearColor(0x000000, 0); //default
	renderer.setSize(window.innerWidth, window.innerHeight);
	renderer.domElement.classList.add('canvas-main');
	body.appendChild(renderer.domElement);

	window.addEventListener('resize', onWindowResize, false);
	function onWindowResize() {
		if (renderer.renderer3D)
			renderer.renderer3D.setSize(renderer.dom.clientWidth, renderer.dom.clientHeight);
		if (renderer.composer)
			renderer.composer.setSize(renderer.dom.clientWidth, renderer.dom.clientHeight);

		camera.aspect = window.innerWidth / window.innerHeight;
		camera.updateProjectionMatrix();
		renderer.setSize(window.innerWidth, window.innerHeight);
	}

	var camera = new THREE.PerspectiveCamera(25, window.innerWidth / window.innerHeight, 1, 500);
	var scene = new THREE.Scene();


	composer = new EffectComposer(renderer);

	scene.fog = new THREE.FogExp2(0x130419, 0.15);
	camera.position.set(0, 0, 10);

	//--------------------------------------------------------------
	var group3d = new THREE.Object3D();
	var geometry = new THREE.BufferGeometry().fromGeometry(new THREE.IcosahedronGeometry(1, 1))

	var gparticular = new THREE.BufferGeometry().fromGeometry(new THREE.CircleGeometry(2.3, 3))
	var bparticular = new THREE.BufferGeometry().fromGeometry(new THREE.CircleGeometry(1, 3))

	var material = new THREE.MeshPhysicalMaterial({ alphaTest: false, transparent: true, color: 0xFFFFFF, shading: THREE.FlatShading }); // @todo
	var bmaterial = new THREE.MeshNormalMaterial({ alphaTest: true, transparent: true, color: 0xFFFFFF, wireframe: false });
	var wmaterial = new THREE.MeshNormalMaterial({ alphaTest: true, transparent: true, color: 0xFFFFFF, wireframe: true });
	var pmaterial = new THREE.MeshNormalMaterial({ alphaTest: true, transparent: true, color: 0xFFFFFF, wireframe: false });


	let animTime = 1.5,
		cEase = 'elastic.out(1, 0.5)'

	function mathRandom(num = 3) {
		var mathnum = -Math.random() * num + Math.random() * num;
		return mathnum;
	}

	// MAKE TRIANGLES
	let pscale, particular;
	for (var i = 1; i < 100; i++) {
		pscale = 0.02 + Math.abs(mathRandom(0.03));
		particular = new THREE.Mesh(gparticular, pmaterial);
		particular.position.set(0, 0, 0);


		particular.rotation.set(mathRandom(), mathRandom(), mathRandom());
		particular.scale.set(pscale, pscale, pscale);

		particular.position.x = mathRandom(6),
			particular.position.y = mathRandom(6),
			particular.position.z = mathRandom(6);
		group3d.add(particular);
	}



	// MAKE CUBE & SQUARE
	var cube = new THREE.Mesh(geometry, material);
	var wcube = new THREE.Mesh(geometry, wmaterial);
	var bcube = new THREE.Mesh(bparticular, bmaterial);

	bcube.scale.set(0.0001, 0.0001, 0.0001);
	cube.scale.set(0.0001, 0.0001, 0.0001);
	bcube.position.z = - 1;
	cube.position.z = - 2;
	cube.position.x = 3;


	// var ambientLight = new THREE.AmbientLight(0x777777, 0.2);
	var lightFront = new THREE.PointLight('#EA0AEA', 1);
	var lightBack = new THREE.PointLight('#4ed0fe', 1);

	lightFront.castShadow = false;

	lightFront.position.set(10, 10, 5);
	lightBack.position.set(-5, -10, -5);



	renderer.setClearColor('#130419', 1);
	// ADD ELEMENTS TO SCENE

	scene.add(lightBack);
	scene.add(lightFront);
	// scene.add(ambientLight);
	scene.add(group3d);
	cube.add(wcube);
	scene.add(bcube);
	scene.add(cube);

	// PREPARE FOR POSTPROCESSING

	var renderScene = new RenderPass(scene, camera);
	composer.addPass(renderScene);

	if (!ismobile) {
		// MAK STUFF GLOW
		var bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);

		var params = {
			exposure: 1,
			bloomStrength: 1.5,
			bloomThreshold: 0.1,
			bloomRadius: 1.1
		};
		bloomPass.threshold = params.bloomThreshold;
		bloomPass.strength = params.bloomStrength;
		bloomPass.radius = params.bloomRadius;

		composer.addPass(bloomPass);
	}

	// MAK STUFF SHAKE
	// var glitchPass = new GlitchPass(1);
	// composer.addPass(glitchPass);
	// glitchPass.enabled = false;

	function rev(str) {
		return parseFloat('-' + str) + 2.5;
	}


	const resetMainCanvas = (pos) => {
		isIntro = true;
		isIntroBlob = false;
		if (about == true) {
			return;
		}
		if (pmaterial.opacity < 0.9) {

			TweenMax.to(pmaterial, animTime, {
				opacity: 1, ease: cEase
			});
		}

		TweenMax.to(bcube.position, animTime, {
			x: 0, y: 0.2, ease: cEase,
		})
		TweenMax.to(bcube.scale, animTime, { x: rev(pos.y / 1.2), y: rev(pos.y / 1.2), z: rev(pos.y / 1.2), ease: cEase })
		TweenMax.to(bcube.rotation, animTime, { z: + pos.y + 4.7 * Math.PI / 180, ease: cEase })

	}

	let isIntro = false, isIntroBlob = false, ilastpox, ilastpoy;
	const introBlob = (pos) => {
		if (about != false || isIntroBlob == true) {
			return;
		}
		isIntroBlob = true

		TweenMax.to(cube.position, animTime, {
			x: pos.x + 4 + (ismobile ? pos.y * 1 : pos.y * 2), y: pos.y, z: 0, ease: cEase
		});

		if (cube.scale.x < 0.4 || cube.scale.x > 0.4) {

			TweenMax.to(cube.scale, animTime,
				{
					x: 0.4, y: 0.4, z: 0.4,
					ease: cEase,

				})

			if (pmaterial.opacity == 0.5) {
				TweenMax.to(pmaterial, animTime, {
					opacity: 1, ease: cEase
				});
			}
		}

	}

	let about = false;
	const moveToAboutTriangle = function (pos) {
		about = true;
		if (isIntro == true || workTriangle == true) {
			return;
		}

		if (!isAboutMoreActive) {
			TweenMax.to(bcube.position, animTime, { x: pos.x, y: pos.y, z: 0, ease: cEase })

			TweenMax.to(bcube.rotation, animTime, {
				x: 0, y: 0, z: pos.y * 3, ease: cEase,
			})
			TweenMax.to(bcube.scale, animTime, { x: 0.2, y: 0.2, z: 0.2, ease: cEase })

		} else {

			TweenMax.to(bcube.position, animTime, { x: 0, y: 1, z: 0, ease: cEase })
			TweenMax.to(bcube.rotation, animTime, { x: 0, y: 0, z: 3, ease: cEase })
			TweenMax.to(bcube.scale, animTime, {
				x: 0, y: 0, z: 0, ease: cEase,
			})
		}

	}


	let toAboutBlob = false;
	const moveToAboutBlob = function (pos) {

		toAboutBlob = true;
		if (work == true || isIntro == true || workTriangle == true) {
			return;
		}
		animateBcube = false;

		if (isAboutMoreActive) {
			if (pmaterial.opacity == 1) {
				TweenMax.to([material, pmaterial, wmaterial], animTime, {
					opacity: 0.5, ease: cEase
				});
			}
			TweenMax.to(cube.position, animTime, { x: 2, y: (parseFloat(pos.y * 1.5)), z: 0, ease: cEase })
		} else {

			if (pmaterial.opacity < 0.9) {
				TweenMax.to(pmaterial, animTime, {
					opacity: 1, ease: cEase
				});
			}

			if (cube.scale.x != 2) {
				TweenMax.to(cube.scale, animTime, {
					x: 2, y: 2, z: 2, ease: cEase,

				})
			}
			TweenMax.to(cube.position, animTime, { x: 2, y: (parseFloat(pos.y * 1.5) - 0.5), z: 0, ease: cEase })

		}


		TweenMax.to(cube.rotation, animTime, { x: pos.y, y: pos.y, z: 1, ease: cEase })
	}

	let workTriangle = false;
	const moveToWorkTriangle = function (pos) {


		if (workText == false) {
			animateBcube = true;
			workTriangle = true;
			if (pmaterial.opacity == 0.5) {
				TweenMax.to(pmaterial, animTime, {
					opacity: 1, ease: cEase
				});
			}
			if (bmaterial.opacity === 0) {
				TweenMax.to(bmaterial, animTime, {
					opacity: 1,
					delay: 0.5, ease: cEase
				});

			}

			if (material.opacity != 1) {
				TweenMax.to([material, pmaterial, wmaterial], animTime, {
					opacity: 1, ease: cEase
				});
			}

			TweenMax.to(bcube.scale, animTime, { x: 1, y: 1, z: 1, ease: cEase });
			TweenMax.to(bcube.rotation, animTime, { x: 0, y: 0, z: 0.52, ease: cEase })
			TweenMax.to(cube.scale, animTime, {
				x: .5, y: .5, z: .5, ease: cEase,
			})

			TweenMax.to(bcube.position, animTime, { x: 0, y: -(parseFloat(pos.y / 1.5)), z: 0, ease: cEase })
			TweenMax.to(cube.position, animTime, { x: 0, y: -(parseFloat(pos.y) / 3), z: 0.1, ease: cEase })

		}

	}

	let workText = false;
	const moveToWorkText = function (pos) {
		workText = true;
		animateBcube = true;
		if (contact == true) {
			return;
		}

		if (pmaterial.opacity == 0 || pmaterial.opacity == 1) {
			TweenMax.to(pmaterial, animTime, {
				opacity: 0.2, ease: cEase
			});

		}
		if (bmaterial.opacity > 0.5) {

			TweenMax.to(bmaterial, animTime, {
				opacity: 0, ease: cEase
			});
		}

		if (cube.scale.x < 0.2 || cube.scale.x > 0.2) {

			TweenMax.to(cube.scale, animTime, {
				x: 0.2, y: 0.2, z: 0.2, ease: cEase,
			})
		}


		TweenMax.to(cube.position, animTime, {
			x: pos.x, y: pos.y, z: 0, ease: cEase
		});

	}


	let throttheContact = true, contact;
	const moveToContact = function (pos) {
		contact = true;
		if (throttheContact == true) {

			throttheContact = false;

			if (pmaterial.opacity != 0 && !ismobile) {
				TweenMax.to(pmaterial, animTime, {
					opacity: 1, ease: cEase
				});

			}
			TweenMax.to(cube.scale, animTime, {
				x: 0.3, y: 0.3, z: 0.3, onComplete: function () {
					throttheContact = true;
				}, ease: cEase
			})

		}
		TweenMax.to(cube.position, animTime, { x: pos.x, y: pos.y, z: 0, ease: cEase })
	}

	const p404 = function () {
		animateBcube = true;
		TweenMax.to(cube.scale, animTime, { x: 2, y: 2, z: 2, ease: cEase })
		TweenMax.to(bcube.scale, animTime, { x: 0, y: 0, z: 0, ease: cEase })
		TweenMax.to(cube.position, animTime, { x: 0, y: 0, z: 0, ease: cEase })
	}


	var vec = new THREE.Vector3();
	var pos = new THREE.Vector3();
	let work = false;
	let items = document.querySelectorAll('[data-action]');
	let rect,
		action;
	const dispatchCanvas = (button = 'btn') => {
		items.forEach((el) => {
			action = el.getAttribute('data-action');
			rect = el.getBoundingClientRect();

			if (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
				body.classList.add('a-' + action)
				vec.set(
					(rect.x / window.innerWidth) * 2 - 1,
					- (rect.y / window.innerHeight) * 2 + 1,
					0.5);
				vec.unproject(camera);
				vec.sub(camera.position).normalize();
				var distance = - camera.position.z / vec.z;
				pos = pos.copy(camera.position).add(vec.multiplyScalar(distance));

				if (action == 'intro') {
					resetMainCanvas(pos);
				}
				if (action == 'intro-blob') {
					introBlob(pos);
				}
				if (action == 'about-triangle') {
					moveToAboutTriangle(pos);
				}
				if (action == 'about-blob') {
					moveToAboutBlob(pos);
				}

				if (action == 'work-triangle') {
					moveToWorkTriangle(pos);
				}
				if (action == 'work') {
					moveToWorkText(pos);
				}
				if (action == 'contact-email') {
					moveToContact(pos);
				}
				if (action == 'p404') {
					p404();
				}
			} else {
				body.classList.remove('a-' + action);
				if (action == 'intro') {
					isIntro = false;
				}
				if (action == 'work') {
					workText = false;
				}
				if (action == 'work-triangle') {
					workTriangle = false;
				}
				if (action == 'about-triangle') {
					about = false;
				}
				if (action == 'about-blob') {
					toAboutBlob = false;
				}
				if (action == 'contact-email') {
					contact = false;
				}
			}
		})

	}

	// const fs = new fastReader('.fast-reader');

	// fs.setWpm(500)
	// fs.start();

	let isAboutMoreActive = false;

	const aboutMoreText = () => {
		isAboutMoreActive = true, nodeIndex = 0;
		document.querySelector('.about').classList.add('about--more');

		TweenMax.to('.text--visible', 0.7,
			{
				opacity: 0,
				ease: Power2.easeOut, delay: 0
			});

		TweenMax.to('.text--invisible,.scroll-area', animTime,
			{
				opacity: 1,
				ease: Power2.easeIn, delay: 0
			});

		TweenMax.to([material, pmaterial, wmaterial], animTime, {
			opacity: 0.5, ease: cEase
		});


		let nodes = document.querySelectorAll('.text__node'),
			nodeIndex = 0, shift = 0;

		const animateNodeChars = (node) => {
			let chars = node.querySelectorAll('.char');
			TweenMax.staggerTo(chars, 0.3, { autoAlpha: 1, ease: Power2.easeOut }, 0.035, allComplete);
			function allComplete() {
				nodeIndex++;
				animateNode(nodes[nodeIndex]);
				top += (node.getBoundingClientRect().height + 30);
				TweenMax.to(bar, 1, {
					scrollTop: top
				})
			}
		}

		let top = 0;
		let bar;
		utils.getElem('.scroll-area', (el) => {
			bar = Scrollbar.init(el, {
				damping: 0.5
			});
		})
		const animateNode = (node) => {
			if (!node) {

				return;
			}
			TweenMax.to(node, animTime,
				{
					opacity: 1,
					ease: cEase,
					onStart: function () {
						animateNodeChars(node);

					}
				});

		}

		setTimeout(() => {
			animateNode(nodes[nodeIndex]);
		}, 500);
	}

	var numRot = 0.001, root = 0, fps = 0;
	let animateBcube = true;

	group3d.scale.x = 0;
	group3d.scale.y = 0;
	group3d.scale.z = 0;

	let animateScene = false;


	document.addEventListener('scroll', utils.throttle(function () {
		dispatchCanvas();
	}, 100));
	console.log(animTime * 1000);

	document.addEventListener('scroll', utils.debounce(function () {
		dispatchCanvas();
	}, animTime * 1000 / 2));
	// document.addEventListener('scroll', utils.debounce(function () {
	// animateScene = false;
	// }, 20));
	TweenMax.to(group3d.scale, 1.2, {
		x: 1,
		y: 1,
		z: 1,
		ease: cEase,
		onComplete: function () {
			animateScene = true;
			dispatchCanvas();

		}
	});
	function animate() {
		if (!ismobile) {
			group3d.rotation.x += numRot;
			group3d.rotation.y += numRot;
			group3d.rotation.z += numRot;
			if (animateBcube == true) {
				cube.rotation.x -= numRot * 5;
				cube.rotation.y -= numRot * 5;
				cube.rotation.z -= numRot * 5;
			}
		}

		composer.render(scene, camera);
		requestAnimationFrame(animate);
	}

	// function gotoSection(section, sectionAnimation) {
	// 	console.log(sectionAnimation);

	// 	document.documentElement.scrollTop = document.querySelector('.' + sectionAnimation).offsetTop - (window.innerHeight / 10);

	// }

	let blobBigger = false;
	const handleEvents = (params) => {

		// utils.getElem('[data-gotosection]', (el) => {
		// 	el.addEventListener('click', () => {
		// 		gotoSection(el.getAttribute('data-gotosection'), el.getAttribute('data-section'));
		// 	});
		// })

		utils.getElem('[data-click]', (el) => {
			let callback = el.getAttribute('data-click')
			el.addEventListener('click', () => {
				if (callback == 'aboutMore') {
					aboutMoreText();
				}
			})
		})

	}


	const setup = () => {
		animate();
		handleEvents();
	}
	setup();

}
